import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { Box, Breadcrumb, Divider, SEO, Text } from '../../../views/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle="মাদরাসাতুল ইলম ফুড এন্ড বুক শপ" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
  <Text variant="h2" textAlign="center" mdxType="Text">
    মাদরাসাতুল ইলম ফুড এন্ড বুক শপ
  </Text>
  <Divider mdxType="Divider" />
  <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/finance/',
        name: 'আর্থিক ব্যাপার'
      }]} mdxType="Breadcrumb" />
  <Divider mdxType="Divider" />
      <p>{`মাদ্রাসাতুল ইল্‌ম এর সাথে জড়িত কিছু দ্বীনি ভাই সরাসরি উৎপাদন স্থল থেকে সংগ্রহ করেন বিভিন্ন পণ্য যেমন- মধু, তেল, ঘি, ইত্যাদি। পন্যগুলো সম্পর্কে জানতে ও অর্ডার করতে নিচে ক্লিক করুন।`}</p>
      <ul>
        <li parentName="ul">
          <Link to="./honey/" mdxType="Link">মধু</Link>
        </li>
        <li parentName="ul">
          <Link to="./ghee/" mdxType="Link">ঘি</Link>
        </li>
        <li parentName="ul">
          <Link to="./mustard-oil/" mdxType="Link">তেল</Link>
        </li>
        <li parentName="ul">
          <Link to="./4d-block/" mdxType="Link">4D Block</Link>
        </li>
        <li parentName="ul">
          <Link to="./library/" mdxType="Link">বুক শপের বইসমূহ</Link>
        </li>
      </ul>
      <Box py={3} mdxType="Box">
  <img src={`/assets/images/কালোজিরা-বরই-ঘি-সরিশা.jpg`} alt="মধু (বরই)" />
      </Box>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      